/*
|--------------------------------------------------------------------------
| Boxes Colors Sections
|--------------------------------------------------------------------------
*/

.general-box {
	padding: 0.5rem;
	width: 100%;
	position: relative;
	h2 {
		color: $white;
		text-align: left;
		font-size: 2.5rem;
		text-transform: capitalize;
		margin: 0 0 0 72px;
		font-weight: 700;
	}
	span.square {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 72px;
		height: 100%;
	}
}

.box-orange {
	@extend .general-box;
	background-color: $orange;
	span.square {
		background-color: $blue;
	}
}

.box-blue {
	@extend .general-box;
	background-color: $blue;
	span.square {
		background-color: $orange;
	}
}


ul.social {
	list-style: none;
	float: right;
	margin: 0;
	li {
		display: inline-block;
		a {
			font-size: 1.2rem;
			padding: 0 0.2rem;
			color: $white;
		}
	}
}

.center {
	text-align: center;
	p {
		text-align: center;
	}
}

.separator {
	max-width: 100%;
	height: 1.375rem;
	margin: 0;
	border: none;
	background-color: transparent;
}

.big-separator {
	@extend .separator;
	height: 2.1875rem;
}

.gray-line {
	@extend .separator;
	width: 90%;
	background-color: $gray-light;
	height: 2px;
	margin: 1rem;
	text-align: center;
}

.zigzag {
	max-width: 35%;
	margin: 0 0 2.18rem 0;
}

/*
|--------------------------------------------------------------------------
| Paddings
|--------------------------------------------------------------------------
*/

.npl {
	padding-left: 0;
}

/*
|--------------------------------------------------------------------------
| Borders
|--------------------------------------------------------------------------
*/

.nbb {
	border-bottom: none !important;
}

/*
|--------------------------------------------------------------------------
| Titles
|--------------------------------------------------------------------------
*/

p.title {
	font-family: $font-source;
	font-weight: 600;
	font-size: 1.375rem;
}

.title-orange {
	font-family: $font-source;
	font-weight: 400;
	font-size: 1.25rem;
	color: $orange;
}

.title-blue {
	@extend p.title;
	color: $blue;
}


/*
|--------------------------------------------------------------------------
| ROWS
|--------------------------------------------------------------------------
*/

.fullwidth {
	max-width: 100%;
	margin: 0;
	.column {
		padding: 0;
	}
}


.relative {
	position: relative;
}


/*
|--------------------------------------------------------------------------
| MODALS
|--------------------------------------------------------------------------
*/

.modaal-overlay {
	background-color: $blue !important;
}

.modaal-content-container {
	padding: 0;
}

.h-span {
	background-color: $orange;
	width: 46px;
	height: 6px; 
	border: 0px;
	margin: 1.7rem auto
}


.message-planner {
	margin-top: 1.5rem;
	
	i {
		color: red;
		font-size: 3rem;
	}

	p {
		text-align: center;		
	}
}

h2.success {
		color: #04B404; font-size: 50px; font-weight: 600; text-align: center; 
	}