section.Home {
	.description {
		h2 {
			font-size: 2rem;
			margin: 0;
			span {
				color: $gray;
				font-size: 1.375rem;
			}
		}

		img {
			max-width: 30%;
			margin: 1.25rem 0 1.5625rem 0;
		}

		@media (max-width: 680px) {
			h2 {
				font-size: 1.5rem;
				span {
					font-size:  1rem;
				}
			}
		}
	}

	.areas {
		h2 {
			&.blue {
				color: $blue;
			}
		}

		p {
			font-size: 0.8125rem;
		} 

		img {
			max-width: 30%;
		}
	}

	.logo-contact {
		text-align: right;
		img {
		}
	}
}

section.home-contact {

	hr.big-separator{
		height: 5.75rem;
	}

	.logo-contact {
		text-align: center;
		img {
			max-width: 50%;
		}
		@include breakpoint(medium) {
			text-align: right;
			img {
				max-width: 70%;
			}	
		}
	}

	.text-contact {
		h2 {
			font-size: 2.325rem;
		}

		p {
			font-size: 0.8125rem;
		}

		@media (max-width: 680px) {
			h2 {
				text-align: center;
			}
		}
	}
} 

section.Offer {
	padding: 2.5rem 0;
	.banner-content {
		position: relative;
		display: inline-block;
		max-width: 231px;

		

		.text {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			padding: 1.3125rem 1.875rem;
			p {
				color: $white;
				font-size: 1.25rem;
				text-shadow: 0px 0px 2px #000000;
			}
		}
	}

	.banner-content-full {
		@extend .banner-content;
		max-width: 1168px;
	}

	#homeOptionalAct {
		margin-top: 3px;
	}

	@media (max-width: 680px) {
		padding: 0px;
		.banner-content {
			max-width: 100%;
			.text {
				top: 50%;
				text-transform: translatey(-50%);
			}
		}

		.banner-content-full {
			.text {
				padding: 0;
				top: 50%;
				text-transform: translatey(-50%);
				p.activities {
				 	
				}
			}
		}
	}
}

section.services {
	padding-top: 30px;
	h1.orange {
		text-align: center;
	}
	p.big-blue {
		color: $blue;
		font-size: 1.2rem;
		line-height: 1;
	}

	h2 {
		font-size: 1.3rem;	
	}
	.menu-services {
		.title-blue {
			display: inline-block;
			a {
				display: inline-block;
				margin-left: 5px;
				color: $orange;
				font-size: 1.25rem;
			}
		}

		@media (max-width: 640px) {
			a.gray-tpt {
				margin: 0;
			}
		}
	}
	@include breakpoint(medium large) {
		h1.orange {
			text-align: left;
		}
		p.big-blue {
			font-size: 1.5625rem;
		}
		h2 {
			font-size: 2.5rem;
		}
	}
}
 
section.act-section {
	.act-content {
		.box-gray {
			background-color: rgba(235,236,236,0.5);
			padding: 1.375rem 1.25rem 1.875rem 1.25rem;
			p {
				color: $gray-medium;
			}
			p.subtitle {
				font-weight: 400;
				font-size: 1.125rem;
				font-family: $font-montse;
			}
			ul, ul.main-restaurant {
				list-style: none;
				li, li.city {
					color: $gray-medium;
					font-size: 1rem;
					button {
						color: $gray-medium;
						font-size: 1rem;
						display: inline-block;
						font-family: $font-source;
						font-weight: 300;
						outline: none;
					}
					&:before {
						content: '\2666';
						font-family: 'FontAwesome';
						color: $orange;
						text-transform: rotate(45deg);
						margin-right: 5px;
					}
					span.arrow {
						color: $orange;
						font-weight: 400;
						margin-left: 5px;
					}
					ul.list {
						display: none;
						list-style: none;
						li.restaurant {

						}
					}
				}
			}
		}
		.divider {
			height: 1px;
			background-color: $gray-light;
			width: 100%;
			display: block;
			border: 0px;
			margin: 2.5rem auto;
		}
	}
}

section.optional-act {
	max-width: 100%;
	overflow: hidden;
	margin-bottom: 2rem;
	.act-container {
		position: absolute;
		left: 0;
		top: 0;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
		.bg-blue {
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background-color: rgba(0,64,119,0.75); 
			padding: 5px;
			text-align: center;
			.info {				
				width: auto;
				position: relative;
				top: 50%;
    			transform: translatey(-50%);
				p {
					color: $white;
					text-align: center;
					font-weight: 400;
					font-size: 1rem;
					text-transform: capitalize;
					margin: 0;
				}
				a.white-tpt {
					width: auto;
					padding: 5px;
					font-size: 0.6rem;
					margin: 0;
					&:hover {
						background-color: rgba(238,117,32,0.7);
						color: $white;
					}
				}
			} 

			@include breakpoint(medium large) {
				padding: 1rem;
				.info {
					p {
						font-size: 2.5rem;
						margin-bottom: 1rem;
					}
					a.white-tpt {
						padding: 1rem;
						font-size: 1rem;
						margin: 1.5rem 0px;
					}
				}
			}
		}

		&:hover {
			.bg-blue { 
				display: block;
			}
		}
	}
}

section.planners {
	h1 {
		text-align: center;
	}
}

section.generales {
	padding-top: 30px;
	p.big-blue {
		color: $blue;
		font-size: 1.5625rem;
		line-height: 1;
	}
}

section.Services-Activities {
	.activity-body {
		padding: 25px;
		.act-description {
			
		}

		.box-details {
			background-color: $gray-light;
			width: 100%;
			padding: 1rem;
			.title-blue {
				font-size: 1rem;
				margin-bottom: 0;
			}
		}

		.title-orange {
			font-size: 1.3rem;
			color: $orange;
			font-family: $font-source;
			font-weight: 600;
		}

		.gray-line {
			margin: 2rem 0 1.5rem 0; 
		}
		button.orange-tpt {
			width: 100%;
			margin: 0;
		}
	}
}

section.login-planners {
	background-color: $white;
	padding: 3rem 0 1rem 0;
	.login-box {
		padding: 1.3rem;
		background-color: $orange;
		a {
			color: $blue;
			font-size: 1rem;
		}

		a.title-box {
			font-size: 1.5rem;
			margin-bottom: 10px;
			color: $white;
			font-weight: 600;
		}

		button {
			background-color: $blue;
			color: $white;
			border-radius: 10px;
			padding: 0.75rem;
			border: 1px solid $gray-medium;
			float: right;
		}

		input {
			margin-right: 1rem;
		}

		label {
			font-size: 1rem;
		}
	}
}