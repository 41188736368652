@charset "UTF-8";
/*
|--------------------------------------------------------------------------
|	Vendor Imports
|--------------------------------------------------------------------------
*/
/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Source+Sans+Pro:300,400,600,700,900|Poppins:300,400,500,600,700);
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.3333333333%; }

.small-push-1 {
  position: relative;
  left: 8.3333333333%; }

.small-pull-1 {
  position: relative;
  left: -8.3333333333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.6666666667%; }

.small-push-2 {
  position: relative;
  left: 16.6666666667%; }

.small-pull-2 {
  position: relative;
  left: -16.6666666667%; }

.small-offset-1 {
  margin-left: 8.3333333333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.6666666667%; }

.small-4 {
  width: 33.3333333333%; }

.small-push-4 {
  position: relative;
  left: 33.3333333333%; }

.small-pull-4 {
  position: relative;
  left: -33.3333333333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.6666666667%; }

.small-push-5 {
  position: relative;
  left: 41.6666666667%; }

.small-pull-5 {
  position: relative;
  left: -41.6666666667%; }

.small-offset-4 {
  margin-left: 33.3333333333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.6666666667%; }

.small-7 {
  width: 58.3333333333%; }

.small-push-7 {
  position: relative;
  left: 58.3333333333%; }

.small-pull-7 {
  position: relative;
  left: -58.3333333333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.6666666667%; }

.small-push-8 {
  position: relative;
  left: 66.6666666667%; }

.small-pull-8 {
  position: relative;
  left: -66.6666666667%; }

.small-offset-7 {
  margin-left: 58.3333333333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.6666666667%; }

.small-10 {
  width: 83.3333333333%; }

.small-push-10 {
  position: relative;
  left: 83.3333333333%; }

.small-pull-10 {
  position: relative;
  left: -83.3333333333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.6666666667%; }

.small-push-11 {
  position: relative;
  left: 91.6666666667%; }

.small-pull-11 {
  position: relative;
  left: -91.6666666667%; }

.small-offset-10 {
  margin-left: 83.3333333333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.6666666667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.3333333333%; }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.6666666667%; }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .medium-offset-1 {
    margin-left: 8.3333333333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.6666666667%; }
  .medium-4 {
    width: 33.3333333333%; }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.6666666667%; }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .medium-offset-4 {
    margin-left: 33.3333333333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.6666666667%; }
  .medium-7 {
    width: 58.3333333333%; }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.6666666667%; }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .medium-offset-7 {
    margin-left: 58.3333333333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.6666666667%; }
  .medium-10 {
    width: 83.3333333333%; }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.6666666667%; }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .medium-offset-10 {
    margin-left: 83.3333333333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.6666666667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.3333333333%; }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .large-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.6666666667%; }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .large-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .large-offset-1 {
    margin-left: 8.3333333333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.6666666667%; }
  .large-4 {
    width: 33.3333333333%; }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .large-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.6666666667%; }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .large-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .large-offset-4 {
    margin-left: 33.3333333333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.6666666667%; }
  .large-7 {
    width: 58.3333333333%; }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .large-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.6666666667%; }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .large-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .large-offset-7 {
    margin-left: 58.3333333333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.6666666667%; }
  .large-10 {
    width: 83.3333333333%; }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .large-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.6666666667%; }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .large-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .large-offset-10 {
    margin-left: 83.3333333333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.6666666667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #fefefe; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #fefefe; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #fefefe; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '–'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.3333333333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.6666666667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    margin-right: 1rem;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed, .flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video, .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen, .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns,
  .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  table tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*!
	Modaal - accessible modals - v0.3.1
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0; }

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out; }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden; }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none; }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1; }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0; }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important; }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden; }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto; }

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff; }
    .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
      background: #b93d0c; }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-close:before {
    transform: rotate(-45deg); }
  .modaal-close:after {
    transform: rotate(45deg); }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px; }

.modaal-content-container {
  padding: 30px; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent; }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out; }
    .modaal-confirm-btn.modaal-ok:hover {
      background: #2f2f2f; }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline; }
    .modaal-confirm-btn.modaal-cancel:hover {
      text-decoration: none;
      color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important; }

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent; }

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }
  .modaal-gallery-item img {
    display: block; }
  .modaal-gallery-item.is_active {
    display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }
  .modaal-gallery-label:focus {
    outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default; }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff; }
    .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
      background: #afb7bc; }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg); }

.modaal-gallery-next {
  left: 100%;
  margin-left: 40px; }

.modaal-gallery-prev {
  right: 100%;
  margin-right: 40px; }
  .modaal-gallery-prev:before, .modaal-gallery-prev:after {
    left: 22px; }
  .modaal-gallery-prev:before {
    margin: 5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-prev:after {
    margin: -5px 0 0;
    transform: rotate(45deg); }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
    .modaal-gallery-control:before, .modaal-gallery-control:after {
      background: #fff; }
  .modaal-gallery-next {
    left: auto;
    right: 20px; }
  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }
  .modaal-instagram iframe {
    width: 600px !important; } }

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25); }

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }

/*
|--------------------------------------------------------------------------
|	Styling Elements
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
html {
  font-size: 16px; }

body {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  width: 100%;
  height: 100%;
  background-color: white; }

p {
  text-align: justify;
  color: #808080;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

a {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  text-decoration: none;
  display: block; }

h1, h2, h3 {
  color: #8D8F92;
  font-size: 1.5625rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.5625rem; }

h1.orange {
  color: #EE7520; }

.slick-dots {
  z-index: 100;
  height: 20px;
  display: none;
  margin-bottom: 20px; }
  .slick-dots li {
    margin-left: 1rem;
    border-radius: 10px; }
    .slick-dots li button {
      border: 1px solid #fff;
      border-radius: 10px; }
    .slick-dots li button:before {
      content: ' ';
      color: transparent;
      border: none;
      font-family: 'FontAwesome'; }
    .slick-dots li.slick-active {
      font-size: 1rem;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.5); }

.gutter-sizer {
  width: 0; }

.grid-item {
  width: 25%;
  margin: 0; }
  .grid-item img {
    width: 100%; }

/*
|--------------------------------------------------------------------------
| Buttons - Colors
|--------------------------------------------------------------------------
*/
a.colors, a.orange {
  padding: 1rem;
  text-align: center;
  margin: 1.5rem 0;
  transition: all 0.5s;
  font-size: 1rem; }
  a.colors:hover, a.orange:hover {
    background-color: transparent;
    transition: all 0.5s; }

a.orange {
  color: #fff;
  background-color: #EE7520; }
  a.orange:hover {
    color: #EE7520; }

/*
|--------------------------------------------------------------------------
| Buttons with background transparent
|--------------------------------------------------------------------------
*/
a.colors-tpt, a.orange-tpt, a.white-tpt, a.gray-tpt, button.orange-tpt {
  padding: 1rem;
  text-align: center;
  margin: 1.5rem 0;
  transition: all 0.5s;
  background-color: transparent;
  font-size: 1rem; }
  a.colors-tpt:hover, a.orange-tpt:hover, a.white-tpt:hover, a.gray-tpt:hover, button.orange-tpt:hover {
    transition: all 0.5s; }

a.orange-tpt {
  color: #EE7520;
  border: 1px solid #EE7520; }
  a.orange-tpt:hover {
    color: #fff;
    background-color: #EE7520; }

a.white-tpt {
  color: #fff;
  border: 1px solid #fff; }
  a.white-tpt:hover {
    color: #EE7520;
    background-color: #fff; }

a.gray-tpt {
  color: #8D8F92;
  border: 1px solid #8D8F92; }
  a.gray-tpt:hover {
    color: #fff;
    background-color: #8D8F92; }

button.orange-tpt {
  color: #EE7520;
  border: 1px solid #EE7520; }
  button.orange-tpt:hover {
    color: #fff;
    background-color: #EE7520; }

a.complete {
  padding: 1.7rem;
  color: white;
  font-size: 2rem;
  text-align: center;
  background-color: #EE7520; }

a.limited {
  padding: 1.7rem;
  color: white;
  font-size: 2rem;
  text-align: center;
  background-color: #004077; }

/*
|--------------------------------------------------------------------------
| Boxes Colors Sections
|--------------------------------------------------------------------------
*/
.general-box, .box-orange, .box-blue {
  padding: 0.5rem;
  width: 100%;
  position: relative; }
  .general-box h2, .box-orange h2, .box-blue h2 {
    color: #fff;
    text-align: left;
    font-size: 2.5rem;
    text-transform: capitalize;
    margin: 0 0 0 72px;
    font-weight: 700; }
  .general-box span.square, .box-orange span.square, .box-blue span.square {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 72px;
    height: 100%; }

.box-orange {
  background-color: #EE7520; }
  .box-orange span.square {
    background-color: #004077; }

.box-blue {
  background-color: #004077; }
  .box-blue span.square {
    background-color: #EE7520; }

ul.social {
  list-style: none;
  float: right;
  margin: 0; }
  ul.social li {
    display: inline-block; }
    ul.social li a {
      font-size: 1.2rem;
      padding: 0 0.2rem;
      color: #fff; }

.center {
  text-align: center; }
  .center p {
    text-align: center; }

.separator, .big-separator, .gray-line {
  max-width: 100%;
  height: 1.375rem;
  margin: 0;
  border: none;
  background-color: transparent; }

.big-separator {
  height: 2.1875rem; }

.gray-line {
  width: 90%;
  background-color: #EBECEC;
  height: 2px;
  margin: 1rem;
  text-align: center; }

.zigzag {
  max-width: 35%;
  margin: 0 0 2.18rem 0; }

/*
|--------------------------------------------------------------------------
| Paddings
|--------------------------------------------------------------------------
*/
.npl {
  padding-left: 0; }

/*
|--------------------------------------------------------------------------
| Borders
|--------------------------------------------------------------------------
*/
.nbb {
  border-bottom: none !important; }

/*
|--------------------------------------------------------------------------
| Titles
|--------------------------------------------------------------------------
*/
p.title, .title-blue {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.375rem; }

.title-orange {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  color: #EE7520; }

.title-blue {
  color: #004077; }

/*
|--------------------------------------------------------------------------
| ROWS
|--------------------------------------------------------------------------
*/
.fullwidth {
  max-width: 100%;
  margin: 0; }
  .fullwidth .column, .fullwidth .columns {
    padding: 0; }

.relative {
  position: relative; }

/*
|--------------------------------------------------------------------------
| MODALS
|--------------------------------------------------------------------------
*/
.modaal-overlay {
  background-color: #004077 !important; }

.modaal-content-container {
  padding: 0; }

.h-span {
  background-color: #EE7520;
  width: 46px;
  height: 6px;
  border: 0px;
  margin: 1.7rem auto; }

.message-planner {
  margin-top: 1.5rem; }
  .message-planner i {
    color: red;
    font-size: 3rem; }
  .message-planner p {
    text-align: center; }

h2.success {
  color: #04B404;
  font-size: 50px;
  font-weight: 600;
  text-align: center; }

section.Home .description h2 {
  font-size: 2rem;
  margin: 0; }
  section.Home .description h2 span {
    color: #8D8F92;
    font-size: 1.375rem; }

section.Home .description img {
  max-width: 30%;
  margin: 1.25rem 0 1.5625rem 0; }

@media (max-width: 680px) {
  section.Home .description h2 {
    font-size: 1.5rem; }
    section.Home .description h2 span {
      font-size: 1rem; } }

section.Home .areas h2.blue {
  color: #004077; }

section.Home .areas p {
  font-size: 0.8125rem; }

section.Home .areas img {
  max-width: 30%; }

section.Home .logo-contact {
  text-align: right; }

section.home-contact hr.big-separator {
  height: 5.75rem; }

section.home-contact .logo-contact {
  text-align: center; }
  section.home-contact .logo-contact img {
    max-width: 50%; }
  @media print, screen and (min-width: 40em) {
    section.home-contact .logo-contact {
      text-align: right; }
      section.home-contact .logo-contact img {
        max-width: 70%; } }

section.home-contact .text-contact h2 {
  font-size: 2.325rem; }

section.home-contact .text-contact p {
  font-size: 0.8125rem; }

@media (max-width: 680px) {
  section.home-contact .text-contact h2 {
    text-align: center; } }

section.Offer {
  padding: 2.5rem 0; }
  section.Offer .banner-content, section.Offer .banner-content-full {
    position: relative;
    display: inline-block;
    max-width: 231px; }
    section.Offer .banner-content .text, section.Offer .banner-content-full .text {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      padding: 1.3125rem 1.875rem; }
      section.Offer .banner-content .text p, section.Offer .banner-content-full .text p {
        color: #fff;
        font-size: 1.25rem;
        text-shadow: 0px 0px 2px #000000; }
  section.Offer .banner-content-full {
    max-width: 1168px; }
  section.Offer #homeOptionalAct {
    margin-top: 3px; }
  @media (max-width: 680px) {
    section.Offer {
      padding: 0px; }
      section.Offer .banner-content, section.Offer .banner-content-full {
        max-width: 100%; }
        section.Offer .banner-content .text, section.Offer .banner-content-full .text {
          top: 50%;
          text-transform: translatey(-50%); }
      section.Offer .banner-content-full .text {
        padding: 0;
        top: 50%;
        text-transform: translatey(-50%); } }

section.services {
  padding-top: 30px; }
  section.services h1.orange {
    text-align: center; }
  section.services p.big-blue {
    color: #004077;
    font-size: 1.2rem;
    line-height: 1; }
  section.services h2 {
    font-size: 1.3rem; }
  section.services .menu-services .title-blue {
    display: inline-block; }
    section.services .menu-services .title-blue a {
      display: inline-block;
      margin-left: 5px;
      color: #EE7520;
      font-size: 1.25rem; }
  @media (max-width: 640px) {
    section.services .menu-services a.gray-tpt {
      margin: 0; } }
  @media screen and (min-width: 40em) {
    section.services h1.orange {
      text-align: left; }
    section.services p.big-blue {
      font-size: 1.5625rem; }
    section.services h2 {
      font-size: 2.5rem; } }

section.act-section .act-content .box-gray {
  background-color: rgba(235, 236, 236, 0.5);
  padding: 1.375rem 1.25rem 1.875rem 1.25rem; }
  section.act-section .act-content .box-gray p {
    color: #808080; }
  section.act-section .act-content .box-gray p.subtitle {
    font-weight: 400;
    font-size: 1.125rem;
    font-family: "Montserrat", sans-serif; }
  section.act-section .act-content .box-gray ul, section.act-section .act-content .box-gray ul.main-restaurant {
    list-style: none; }
    section.act-section .act-content .box-gray ul li, section.act-section .act-content .box-gray ul li.city, section.act-section .act-content .box-gray ul.main-restaurant li, section.act-section .act-content .box-gray ul.main-restaurant li.city {
      color: #808080;
      font-size: 1rem; }
      section.act-section .act-content .box-gray ul li button, section.act-section .act-content .box-gray ul li.city button, section.act-section .act-content .box-gray ul.main-restaurant li button, section.act-section .act-content .box-gray ul.main-restaurant li.city button {
        color: #808080;
        font-size: 1rem;
        display: inline-block;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 300;
        outline: none; }
      section.act-section .act-content .box-gray ul li:before, section.act-section .act-content .box-gray ul li.city:before, section.act-section .act-content .box-gray ul.main-restaurant li:before, section.act-section .act-content .box-gray ul.main-restaurant li.city:before {
        content: '\2666';
        font-family: 'FontAwesome';
        color: #EE7520;
        text-transform: rotate(45deg);
        margin-right: 5px; }
      section.act-section .act-content .box-gray ul li span.arrow, section.act-section .act-content .box-gray ul li.city span.arrow, section.act-section .act-content .box-gray ul.main-restaurant li span.arrow, section.act-section .act-content .box-gray ul.main-restaurant li.city span.arrow {
        color: #EE7520;
        font-weight: 400;
        margin-left: 5px; }
      section.act-section .act-content .box-gray ul li ul.list, section.act-section .act-content .box-gray ul li.city ul.list, section.act-section .act-content .box-gray ul.main-restaurant li ul.list, section.act-section .act-content .box-gray ul.main-restaurant li.city ul.list {
        display: none;
        list-style: none; }

section.act-section .act-content .divider {
  height: 1px;
  background-color: #EBECEC;
  width: 100%;
  display: block;
  border: 0px;
  margin: 2.5rem auto; }

section.optional-act {
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 2rem; }
  section.optional-act .act-container {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden; }
    section.optional-act .act-container img {
      width: 100%;
      height: 100%; }
    section.optional-act .act-container .bg-blue {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 64, 119, 0.75);
      padding: 5px;
      text-align: center; }
      section.optional-act .act-container .bg-blue .info {
        width: auto;
        position: relative;
        top: 50%;
        transform: translatey(-50%); }
        section.optional-act .act-container .bg-blue .info p {
          color: #fff;
          text-align: center;
          font-weight: 400;
          font-size: 1rem;
          text-transform: capitalize;
          margin: 0; }
        section.optional-act .act-container .bg-blue .info a.white-tpt {
          width: auto;
          padding: 5px;
          font-size: 0.6rem;
          margin: 0; }
          section.optional-act .act-container .bg-blue .info a.white-tpt:hover {
            background-color: rgba(238, 117, 32, 0.7);
            color: #fff; }
      @media screen and (min-width: 40em) {
        section.optional-act .act-container .bg-blue {
          padding: 1rem; }
          section.optional-act .act-container .bg-blue .info p {
            font-size: 2.5rem;
            margin-bottom: 1rem; }
          section.optional-act .act-container .bg-blue .info a.white-tpt {
            padding: 1rem;
            font-size: 1rem;
            margin: 1.5rem 0px; } }
    section.optional-act .act-container:hover .bg-blue {
      display: block; }

section.planners h1 {
  text-align: center; }

section.generales {
  padding-top: 30px; }
  section.generales p.big-blue {
    color: #004077;
    font-size: 1.5625rem;
    line-height: 1; }

section.Services-Activities .activity-body {
  padding: 25px; }
  section.Services-Activities .activity-body .box-details {
    background-color: #EBECEC;
    width: 100%;
    padding: 1rem; }
    section.Services-Activities .activity-body .box-details .title-blue {
      font-size: 1rem;
      margin-bottom: 0; }
  section.Services-Activities .activity-body .title-orange {
    font-size: 1.3rem;
    color: #EE7520;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600; }
  section.Services-Activities .activity-body .gray-line {
    margin: 2rem 0 1.5rem 0; }
  section.Services-Activities .activity-body button.orange-tpt {
    width: 100%;
    margin: 0; }

section.login-planners {
  background-color: #fff;
  padding: 3rem 0 1rem 0; }
  section.login-planners .login-box {
    padding: 1.3rem;
    background-color: #EE7520; }
    section.login-planners .login-box a {
      color: #004077;
      font-size: 1rem; }
    section.login-planners .login-box a.title-box {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #fff;
      font-weight: 600; }
    section.login-planners .login-box button {
      background-color: #004077;
      color: #fff;
      border-radius: 10px;
      padding: 0.75rem;
      border: 1px solid #808080;
      float: right; }
    section.login-planners .login-box input {
      margin-right: 1rem; }
    section.login-planners .login-box label {
      font-size: 1rem; }

/*
|--------------------------------------------------------------------------
| Parallax
|--------------------------------------------------------------------------
*/
.parallax {
  height: 200px;
  background: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed; }
  @media (min-width: 767px) {
    .parallax {
      height: 454px; } }

.paradise {
  background-image: url(/images/bg/paradise.jpg);
  background-size: cover;
  padding: 1rem 0.5rem; }
  @media screen and (min-width: 40em) {
    .paradise {
      padding: 2.9rem; } }

.page-header, .head-services, .head-aboutus, .head-g20 {
  height: 500px;
  background: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed; }
  .page-header img.zig-zag, .head-services img.zig-zag, .head-aboutus img.zig-zag, .head-g20 img.zig-zag {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%; }

.head-services {
  background-image: url(/images/bg/head-services.jpg); }

.img-logo {
  width: 80%;
  text-align: center; }

.head-aboutus {
  background-image: url(/images/bg/head-aboutus.jpg); }

.head-g20 {
  background-image: url(/images/bg/head-g20.jpg); }

.head-login {
  height: 300px;
  background: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(/images/login-head.jpg); }

#Slider-Home .slick-dots {
  margin: 0;
  bottom: 2rem;
  margin-left: -15%; }

#testimonials-slide .testimonials-text {
  margin-top: 0;
  text-align: center; }
  #testimonials-slide .testimonials-text h3 {
    font-size: 1.5rem;
    color: #fff;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 0.5rem; }
  #testimonials-slide .testimonials-text p {
    text-align: center;
    color: #fff;
    font-size: 0.875rem; }
  #testimonials-slide .testimonials-text a {
    padding: 0.5rem;
    margin: 0; }
    #testimonials-slide .testimonials-text a:hover {
      color: #004077;
      background-color: rgba(255, 255, 255, 0.5); }

#testimonials-slide .slick-dots {
  top: 0; }

@media screen and (min-width: 40em) {
  #testimonials-slide .testimonials-text {
    margin-top: 4rem; }
    #testimonials-slide .testimonials-text h3 {
      font-size: 3.5rem;
      margin-bottom: 1rem; }
    #testimonials-slide .testimonials-text a {
      padding: 1rem;
      margin: 1.5rem 0; } }

#business-slide {
  background-color: #EBECEC;
  padding: 3.5rem 2.5rem  1.3rem 2.5rem; }
  #business-slide img {
    display: inline-block;
    margin-right: 3rem; }
  #business-slide .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 2rem; }
    #business-slide .slick-dots li button {
      border: 1px solid #808080; }
    #business-slide .slick-dots li.slick-active {
      color: #808080;
      background-color: rgba(128, 128, 128, 0.5); }

#teambuilding-slide .slick-dots, #dinearound-slide .slick-dots, #events-slide .slick-dots, #amenities-slide .slick-dots, #transportation-slide .slick-dots {
  bottom: 1rem; }

.slidershadow {
  position: absolute;
  width: 100%;
  height: 398px;
  left: 0;
  top: 0;
  z-index: 99;
  background: url(/images/slider/shadow.png) repeat-x top center; }

#sliderCont {
  position: relative;
  fill: #fff; }

#curvahome {
  position: absolute;
  bottom: 0px;
  max-height: 150px;
  width: 100%; }

.curvatura {
  position: absolute;
  bottom: -1px;
  max-height: 282px;
  width: 100%; }

.planner-form label {
  font-size: 1.1rem;
  font-family: "Source Sans Pro", sans-serif;
  color: #58595B;
  font-weight: 600;
  text-transform: none; }
  .planner-form label:after {
    content: ':'; }

.planner-form input {
  margin: 0;
  height: 2rem; }

header {
  position: absolute;
  width: 100%;
  background-color: transparent;
  top: 0;
  z-index: 100; }
  header .img-logo {
    text-align: center; }
  header a, header p {
    color: #fff;
    display: inline-block;
    margin: 0; }
  header p {
    font-size: 10px;
    font-family: "Montserrat", sans-serif; }
  header a {
    font-size: 0.875rem;
    margin-left: 5px;
    font-family: "Montserrat", sans-serif; }
    header a:hover {
      color: #EE7520; }
  header a.language {
    margin: 0; }
    header a.language:hover {
      color: #EBECEC; }
  header ul.social {
    margin-right: 1rem; }
  header span.separator, header span.big-separator, header span.gray-line {
    display: inline-block;
    margin: 0 0.4rem;
    color: #EE7520;
    font-size: 1rem;
    font-weight: 600; }
  header .top-header {
    padding: 1.625rem 0 0 0; }
  @media (max-width: 640px) {
    header .top-header p {
      display: block;
      text-align: center; }
    header ul.social {
      display: block;
      margin: 0;
      float: none;
      text-align: center; }
      header ul.social li {
        text-align: center; }
    header .img-logo {
      text-align: center;
      width: 100%; }
      header .img-logo img {
        max-width: 50%; } }
  header nav ul#theMenu.menu {
    list-style: none;
    position: relative;
    table-layout: auto !important;
    margin-top: 2rem; }
    header nav ul#theMenu.menu li {
      transition: all 0.5;
      text-align: center;
      display: block; }
      header nav ul#theMenu.menu li a {
        padding: 0; }
        header nav ul#theMenu.menu li a:hover {
          color: #fff; }
      @media screen and (min-width: 40em) {
        header nav ul#theMenu.menu li {
          display: table-cell; }
          header nav ul#theMenu.menu li ul li {
            display: block; } }
    header nav ul#theMenu.menu li.dropdown {
      position: relative;
      padding-bottom: 0; }
      header nav ul#theMenu.menu li.dropdown:after {
        content: '\f0d7';
        font-family: 'FontAwesome';
        color: #fff;
        position: absolute;
        display: block;
        width: 100%; }
      header nav ul#theMenu.menu li.dropdown ul.dropdown-menu {
        min-width: 180px;
        display: none;
        list-style: none;
        margin: 25px 0 0 0;
        border-bottom: 4px solid #004077; }
        header nav ul#theMenu.menu li.dropdown ul.dropdown-menu li a {
          padding: 1rem 2rem 0.375rem 0.875rem;
          width: 100%;
          color: #fff;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 300;
          font-size: 0.875rem;
          text-transform: capitalize;
          transition: all 0.5s; }
      header nav ul#theMenu.menu li.dropdown:hover:after {
        color: #EE7520; }
      header nav ul#theMenu.menu li.dropdown:hover ul.dropdown-menu {
        display: block; }
  header div#DesktopMenu {
    display: none;
    background-color: #EE7520; }
    header div#DesktopMenu ul#theMenu li a {
      padding: 10px; }
    header div#DesktopMenu ul#theMenu li.dropdown ul.dropdown-menu {
      position: relative; }
      header div#DesktopMenu ul#theMenu li.dropdown ul.dropdown-menu li {
        background-color: rgba(255, 255, 255, 0.5);
        border-bottom: 2px solid #8D8F92;
        text-align: center; }
        header div#DesktopMenu ul#theMenu li.dropdown ul.dropdown-menu li a {
          color: #8D8F92; }
        header div#DesktopMenu ul#theMenu li.dropdown ul.dropdown-menu li:hover {
          background-color: rgba(255, 255, 255, 0.75);
          transition: all 0.5s; }
          header div#DesktopMenu ul#theMenu li.dropdown ul.dropdown-menu li:hover a {
            color: #EE7520; }
    header div#DesktopMenu ul#theMenu li.dropdown:after {
      display: inline-block; }
    @media screen and (min-width: 40em) {
      header div#DesktopMenu {
        display: block;
        background-color: rgba(255, 255, 255, 0); }
        header div#DesktopMenu ul#theMenu > li > a {
          padding: 0; }
        header div#DesktopMenu ul#theMenu > li.dropdown:after {
          display: block; }
        header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu {
          position: absolute; }
          header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu li {
            background-color: rgba(141, 143, 146, 0.75);
            border-bottom: 2px solid #8D8F92;
            text-align: left; }
            header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu li a {
              color: #fff; }
            header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu li:hover {
              background-color: rgba(128, 128, 128, 0.75);
              transition: all 0.5s; }
              header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu li:hover a {
                color: #fff; }
            header div#DesktopMenu ul#theMenu > li.dropdown ul.dropdown-menu li:after {
              display: block; } }
  header #Hamburguer {
    cursor: pointer;
    text-align: center;
    color: #EE7520; }
    @media screen and (min-width: 40em) {
      header #Hamburguer {
        display: none; } }

footer {
  background-color: #004077;
  padding: 3.625rem 0;
  border-top: 5px solid #EE7520;
  margin-top: 2rem; }
  footer p, footer a {
    color: #fff;
    font-size: 0.75rem; }
  footer p.title, footer .title-blue {
    text-align: center;
    font-size: 1.375rem;
    font-weight: 400;
    text-transform: capitalize; }
    @media print, screen and (min-width: 40em) {
      footer p.title, footer .title-blue {
        text-align: left; } }
  footer a:hover {
    color: #EE7520; }
  footer ul.social {
    float: left;
    margin-top: 1rem; }
    footer ul.social li a {
      font-size: 1.5rem; }
      footer ul.social li a:hover {
        color: #EE7520; }
  @media (max-width: 680px) {
    footer ul.social {
      float: none;
      text-align: center; }
    footer a.sitemap {
      padding: 0.75rem; }
    footer p.contact {
      text-align: center; } }
  footer ul.menu-footer {
    list-style: none;
    margin: 0;
    text-align: center; }
    footer ul.menu-footer li a {
      font-weight: 300; }
    @media print, screen and (min-width: 40em) {
      footer ul.menu-footer {
        text-align: left; } }
  footer a.links {
    display: inline-block; }

.post-footer {
  background-color: #8D8F92;
  padding: 0.75rem 1rem 1.25rem 1rem; }
  .post-footer p {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.6875rem;
    margin: 0; }
