/*
|--------------------------------------------------------------------------
| Parallax
|--------------------------------------------------------------------------
*/

.parallax {
	height: 200px;
	background: $white;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-attachment: fixed;
	@media (min-width: 767px) {
		height: 454px;
	}
}

.paradise {
	background-image: url(/images/bg/paradise.jpg);
	background-size: cover;
	padding: 1rem 0.5rem;
	@include breakpoint(medium large) {
		padding: 2.9rem;
	}
}