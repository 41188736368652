footer {
	background-color: $blue;
	padding: 3.625rem 0;
	border-top: 5px solid $orange;
	margin-top: 2rem;

	p,a {
		color: $white; 
		font-size: 0.75rem;
	}

	p.title {
		text-align: center;
		font-size: 1.375rem;
		font-weight: 400;
		text-transform: capitalize;
		@include breakpoint(medium) {
			text-align: left;
		}
	}
	
	a {
		&:hover {
			color: $orange;
		} 
	}
	ul.social {
		float: left;
		margin-top: 1rem;
		li {
			a {
				font-size: 1.5rem;
				&:hover {
					color: $orange;
				}
			}
		}
	}

	@media (max-width: 680px) {
		ul.social {
			float: none;
			text-align: center;
		}

		a.sitemap {
			padding: 0.75rem;
		}

		p.contact {
			text-align: center;
		}
	}

	ul.menu-footer {
		list-style: none;
		margin: 0;
		text-align: center;
		li {
			a {
				font-weight: 300;
			}
		}
		@include breakpoint(medium) {
			text-align: left;
		}
	}

	a.links {
		display: inline-block;
	}
}

.post-footer {
	background-color: $gray;
	padding: 0.75rem 1rem 1.25rem 1rem;

	p {
		text-align: center;
		color: $white;
		text-transform: uppercase;
		font-size: 0.6875rem;
		margin: 0;
	}
}