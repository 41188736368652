.page-header {
 	height: 500px;
	background: $white;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-attachment: fixed;
	img.zig-zag {
		position: absolute;
		z-index: 1;
		bottom: 0;
		width: 100%;
	}
} 

.head-services {
	@extend .page-header;
	background-image: url(/images/bg/head-services.jpg);
}

.img-logo {
	width: 80%;
	text-align: center;
}


.head-aboutus {
	@extend .page-header;
	background-image: url(/images/bg/head-aboutus.jpg);
}

.head-g20 {
	@extend .page-header;
	background-image: url(/images/bg/head-g20.jpg);
}

.head-login {
	height: 300px;
	background: $white;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-attachment: fixed;
	background-image: url(/images/login-head.jpg);
}