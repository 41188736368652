/*
|--------------------------------------------------------------------------
|	Vendor Imports
|--------------------------------------------------------------------------
*/

@import "./vendor/bower_components/foundation-sites/scss/foundation";
@include foundation-everything;
$reveal-background: $black;
@import "vendor/bower_components/slick-carousel/slick/slick.scss";
@import "vendor/bower_components/slick-carousel/slick/slick-theme.scss";
@import "vendor/bower_components/modaal/dist/css/modaal.scss";

/*
|--------------------------------------------------------------------------
|	Styling Elements
|--------------------------------------------------------------------------
*/
@import "elements/colors";
@import "elements/fonts";
@import "elements/general";
@import "elements/buttons";
@import "elements/utilities";
@import "elements/sections";
@import "elements/parallax";
@import "elements/headers";
@import "elements/sliders";
@import "elements/forms";
@import "components/header"; 
@import "components/footer";
