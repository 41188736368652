/*
|--------------------------------------------------------------------------
| Buttons - Colors
|--------------------------------------------------------------------------
*/
a.colors {
	padding: 1rem;
	text-align: center;
	margin: 1.5rem 0;
	transition: all 0.5s;
	font-size: 1rem;
	&:hover {
		background-color: transparent;
		transition: all 0.5s;
	}
}
a.orange {
	@extend a.colors;
	color: $white;
	background-color: $orange;
	&:hover {
		color: $orange;
	}
}


/*
|--------------------------------------------------------------------------
| Buttons with background transparent
|--------------------------------------------------------------------------
*/

a.colors-tpt {
	padding: 1rem;
	text-align: center;
	margin: 1.5rem 0;
	transition: all 0.5s;
	background-color: transparent;
	font-size: 1rem;
	&:hover {
		transition: all 0.5s;
	}
}

a.orange-tpt {
	@extend a.colors-tpt;
	color: $orange;
	border: 1px solid $orange;
	&:hover {
		color: $white;
		background-color: $orange
	}
}

a.white-tpt {
	@extend a.colors-tpt;
	color: $white;
	border: 1px solid $white;
	&:hover {
		color: $orange;
		background-color: $white;
	}
}

a.gray-tpt {
	@extend a.colors-tpt;
	color: $gray;
	border: 1px solid $gray;
	&:hover {
		color: $white;
		background-color: $gray;
	}
}

button.orange-tpt {
	@extend a.colors-tpt;
	color: $orange;
	border: 1px solid $orange;
	&:hover {
		color: $white;
		background-color: $orange
	}
}

a.complete {
	padding: 1.7rem;
	color: white;
	font-size: 2rem;
	text-align: center;
	background-color: $orange;
}

a.limited {
	padding: 1.7rem;
	color: white;
	font-size: 2rem;
	text-align: center;
	background-color: $blue;
}